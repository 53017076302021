import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Banner from '../../components/ui/Banner';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const CTA = makeShortcode("CTA");
const layoutProps = {
  _frontmatter
};

const MDXLayout = function HeroBanner(props) {
  return <Banner title='Software engineer & Trainer' subtitle='I am Simon'>
      {props.children}
    </Banner>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`I'm a visionary for software engineering and a pragmatist in writing code. I love teaching how I build stuff with the click-clacks of my keyboard because I'm talented in explaining. If a culture of conveying know-how to team mates prevails, even complex software projects will succeed.`}</p>
    <CTA to="/resume/" label="View My Profile" mdxType="CTA" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      