import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/smartnuance/blog/src/components/Layout/index.tsx";
import HeroBanner from '../data/hero';
import { Services, Service } from 'components/Services';
import Projects from 'components/Projects';
import { BracketsCurly, UsersThree } from "phosphor-react";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const Container = makeShortcode("Container");
const TitleSection = makeShortcode("TitleSection");
const CTA = makeShortcode("CTA");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="About" mdxType="SEO" />
    <HeroBanner mdxType="HeroBanner" />
    <Container section mdxType="Container">
      <TitleSection title="open source" subtitle="Highlighted projects" center mdxType="TitleSection" />
      <Projects mdxType="Projects" />
      <CTA to="/blog" label="see all articles" center mdxType="CTA" />
    </Container>
    <Container section mdxType="Container">
      <TitleSection title="for companies & startups" subtitle="Count on me" center mdxType="TitleSection" />
      <Services mdxType="Services">
        <Service title="Boost your product" icon={<BracketsCurly mdxType="BracketsCurly" />} mdxType="Service">
          <p>{`Hire me as a freelancer for the critical phase of your product. Profit from my profound experience with a `}<a parentName="p" {...{
              "href": "/resume"
            }}>{`huge number of technologies`}</a>{`. In many corporate and side projects I proofed how I do not only adapt a tech stack quickly but also thrive it forward to be simpler but complete enough to deliver stable software that we all actually want to use.`}</p>
        </Service>
        <Service title="Boost your team" icon={<UsersThree mdxType="UsersThree" />} mdxType="Service">
          <p>{`Delivering on my own is easy but limited by my finite time. That's why I prefer collaborations where I work closely with a team and empower them in a few months to do fine without me or having me just as an occasional reviewer or last resort for difficult decisions. I achieve this by coaching team members individually, exemplifying a culture of humble learning and teaching. I never capture knowledge, I share it openly until team members are perfectly ready to take over my lead. I ensure this by`}</p>
          <ul>
            <li parentName="ul">{`helping to find consensus of fundamental tech choices`}</li>
            <li parentName="ul">{`writing excellent documentation for technical design decisions and established engineering standards`}</li>
            <li parentName="ul">{`using `}<a parentName="li" {...{
                "href": "/blog/flutter-admin-kit/"
              }}>{`tutorial style docs and videos`}</a>{` to provide a seemless on-boarding experience for new team joiners`}</li>
          </ul>
        </Service>
      </Services>
    </Container>
    <Container section mdxType="Container">
      <p>{`If any of my projects or services got your interest, contact me and we can discuss your challenge and how I could be helpful to tackle it:`}</p>
      <p><strong parentName="p"><a parentName="strong" {...{
            "href": "mailto:simon@smartnuance.com"
          }}>{`simon@smartnuance.com`}</a></strong></p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      